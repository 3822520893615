import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';


export default function BlogPage({location}) {
    // const pageQuery = useStaticQuery(graphql`
    //     query {
    //         allStrapiArticleCategories {
    //             edges {
    //                 node{
    //                     seo {
    //                         metaTitle
    //                         metaDescription
    //                         h1
    //                         url {
    //                             link
    //                         }
    //                         shareImage {
    //                             url
    //                         }
    //                     }
    //                     articles {
    //                         seo {
    //                             metaDescription
    //                             h1
    //                             url {
    //                                 link
    //                             }
    //                             shareImage {
    //                                 url
    //                             }  
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }`)

    return (
        <Layout>
            <GatsbySeo canonical={location.href} title="naturalvoicer blog" description="naturalvoicer blog" />
            {/* <section className="text-center bg-light pt-28 pb-12">
                    <div className="container mx-auto">
                        <h1 className="font-raleway font-extrabold text-5xl text-blue-darkest">Blog</h1>
                        <p className="text-lg text-gray pt-5">Interesting ideas to help you understand better.</p>
                    </div>
            </section>
            <div className="w-full container mx-auto">
                <section className="pt-10 pb-12">
                    <div className="container">
                        {
                            pageQuery.allStrapiArticleCategories.edges.map(({node},index)=>{
                                return (
                                <div key={index} className="pb-10">
                                    <div className="font-raleway font-bold text-blue-dark text-2xl pb-6">
                                        {node.seo.h1}
                                    </div>
                                    <div className="grid grid-cols-3 gap-3">
                                        {
                                            node.articles.map((n,i)=>{
                                                return (
                                                <div key={i}>
                                                    <a href={n.seo.url.link} className="pic">
                                                        <figure><img src={n.seo.shareImage.url} alt="articles picture"/></figure>
                                                    </a>
                                                    <div className="text-xl font-semibold"><a href={n.seo.url.link}>{n.seo.h1}</a></div>
                                                    <p className="desc text-gray">{n.seo.metaDescription}</p>
                                                </div>)
                                                
                                            })
                                        }
                                    </div>
                                </div>)
                            })
                        }
                    </div>
                </section>
            </div> */}
        </Layout>
    )
}

